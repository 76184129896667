import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "dlg-add-targets", attrs: { "ok-text": "Create", "title": "Add Targets", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v(" Add ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Facility", "form-item": "", "source": "id", "reference": "line-performance.common.local-facilities", "source-label": _vm.generateLabel, "span": 12, "value": _vm.facility.value, "rules": "required", "disabled": true }, on: { "change": _vm.onFacilityChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "areaID", attrs: { "form-item": "", "label": "Department", "data-source": _vm.listAreas, "source": "id", "source-label": "name", "span": 12, "value": _vm.area.value, "default-active-first-option": "" }, on: { "change": _vm.onAreaChange } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Line", "placeholder": "Select Line", "reference": "line-performance.common.local-production-lines", "custom-query": _vm.areaId ? "areaId=" + _vm.areaId : "", "source": "id", "source-label": "code", "rules": "required", "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("productionLineID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "OEE Target", "rules": "required", "min": 0, "max": 100, "span": 24, "suffix": "%", "type": "decimal" }, on: { "change": function($event) {
      return _vm.storeValue("oeeTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Availability Target", "rules": "required", "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("availabilityTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Performance Target", "rules": "required", "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("performanceTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Quality Target", "rules": "required", "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("qualityTarget", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateTargets",
  inject: [
    "apiUrl",
    "crud",
    "redirectRoute",
    "resourceName",
    "facility",
    "area",
    "getListAreas"
  ],
  data() {
    return {
      createForm: {},
      visible: false,
      arrAreaCode: [],
      arrLineDesc: [],
      lineDesc: "",
      areaCode: null,
      isLoading: false,
      currentYear: this.$moment().year(),
      currentMonth: this.$moment().month(),
      facilityId: this.facility.value,
      areaId: this.area.value
    };
  },
  computed: {
    listAreas() {
      return this.getListAreas();
    }
  },
  mounted() {
    this.visible = true;
  },
  created() {
    this.storeValue("currentYear", this.currentYear);
    this.storeValue("month", this.currentMonth);
    this.storeValue("facilityID", this.facility.value);
    this.storeValue("areaID", this.area.value);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onFacilityChange(value) {
      this.storeValue("facilityId", value);
      this.facilityId = value;
    },
    onAreaChange(value) {
      this.storeValue("areaID", value);
      this.areaId = value;
    },
    generateLabel(facility) {
      return facility.description ? `${facility.name} - ${facility.description}` : `${facility.name}`;
    },
    submit() {
      this.isLoading = true;
      this.crud.submitEntity("create").then(() => {
        this.crud.fetchList();
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push("/line-performance/targets");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateTargets = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-product-standard" }, [_c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-areas", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-production-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "identities.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.month-target", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.targets", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/targets" } }, [_c("create-targets")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateTargets
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
